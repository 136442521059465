// extracted by mini-css-extract-plugin
export var africarColor = "c2_xR";
export var ammoColor = "c2_xx";
export var babelColor = "c2_xQ";
export var cakerColor = "c2_xB";
export var chatticColor = "c2_xy";
export var danColor = "c2_xz";
export var dumaColor = "c2_xK";
export var dvColor = "c2_xT";
export var impactColor = "c2_xF";
export var ixColor = "c2_xV";
export var malleniColor = "c2_xD";
export var pmkColor = "c2_xP";
export var refColor = "c2_xL";
export var rewColor = "c2_xM";
export var timebotColor = "c2_xH";
export var tradeaboatColor = "c2_xJ";
export var tradervsColor = "c2_xG";
export var variaColor = "c2_xC";
export var wownerColor = "c2_xN";
export var xpcColor = "c2_xS";